<template>
  <el-container class="member">
    <el-main>
      <div style="width: 800px; margin: 0 auto">
        <el-container>
          <el-header class="flex-box flex-start text-bold page-title">
            个人信息
          </el-header>
          <el-main>
            <el-form
              inline
              :rules="RulesUser"
              :model="formUser"
              ref="formUser"
              style="width: 100%"
              label-width="120px"
            >
              <el-form-item label="姓名：" prop="name">
                <el-input
                  maxlength="4"
                  class="field-half"
                  placeholder="请输入姓名"
                  v-model="formUser.name"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机号码：" prop="phone">
                <el-input
                  maxlength="11"
                  class="field-half"
                  placeholder="请输入手机号码"
                  v-model.number="formUser.phone"
                ></el-input>
              </el-form-item>
              <el-form-item label="登录密码：" prop="password">
                <el-input
                  maxlength="16"
                  class="field-half"
                  placeholder="请设置登录"
                  v-model.number="formUser.password"
                ></el-input>
              </el-form-item>
              <el-form-item label="身份证号：" prop="idCard">
                <el-input
                  maxlength="18"
                  class="field-half"
                  placeholder="请输入身份证号"
                  v-model="formUser.idCard"
                ></el-input>
              </el-form-item>
              <el-form-item label="QQ 号：" prop="qq">
                <el-input
                  maxlength="12"
                  class="field-half"
                  placeholder="请输入QQ 号"
                  v-model.number="formUser.qq"
                ></el-input>
              </el-form-item>
              <el-form-item label="微信号：" prop="wechat">
                <el-input
                  class="field-half"
                  placeholder="请输入微信号"
                  v-model="formUser.wechat"
                ></el-input>
              </el-form-item>

              <el-form-item label=" ">
                <el-checkbox v-model="checked">
                  同意将实名信息用于凭证云实名认证
                </el-checkbox>
              </el-form-item>

              <el-form-item>
                <div style="width: 720px" class="text-center">
                  <el-button
                    type="primary"
                    :loading="loading"
                    style="margin-right: 35px"
                    @click.native.prevent="handleSubmit"
                  >
                    提交
                  </el-button>

                  <el-button
                    :loading="loading"
                    @click.native.prevent="handleCancel"
                  >
                    取消
                  </el-button>
                </div>
              </el-form-item>
            </el-form>
          </el-main>
        </el-container>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { hasProperty } from "@/common/utils";
import { registerByPerfect } from "@/api/user";

let pwdRegExp = /^\d{6}$/;
export default {
  name: "Member",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!pwdRegExp.test(value)) {
        callback(new Error("请输入6位数字密码"));
      } else {
        callback();
      }
    };

    return {
      formUser: {
        qq: "",
        name: "",
        phone: "",
        wechat: "",
        userId: "",
        idCard: "",
        password: "",
      },
      checked: false,
      loading: false,
      RulesUser: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入姓名",
          },
        ],
        phone: [
          {
            required: true,
            trigger: "blur",
            message: "请输入手机号码",
          },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        idCard: [
          {
            required: true,
            trigger: "blur",
            message: "请输入身份证号",
          },
        ],
      },
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    userInfo: {
      handler(val) {
        for (const key in this.formUser) {
          if (hasProperty(this.formUser, key)) {
            this.formUser[key] = val[key];
          }
        }
        this.formUser.userId = val.id;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.formUser.validate((valid) => {
        if (valid) {
          if (this.checked) {
            registerByPerfect(this.formUser).then((res) => {
              this.$store.commit(
                "setUserInfo",
                Object.assign(this.userInfo, res.data)
              );
              this.$router.push({ path: "/" });
            });
          } else {
            this.$message.error("请同意将实名信息用于凭证云实名认证！");
          }
        }
      });
    },
    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less">
.member {
  .el-form {
    &-item {
      margin-bottom: 25px;

      .field {
        width: 530px;

        &-half {
          width: 200px;
        }
      }

      .el-button {
        width: 100px;
      }
    }
  }
}
</style>
